import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Alert} from "antd";
import logo from "../../assets/images/sp/sp-logo-8.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import zurich1 from "../../assets/images/sp/8/zurich-1.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Zurich | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 8</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.zurich.ch/it" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 14, order: 1}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">Zurich Svizzera</h6>
                                <h3>Un’occasione da non perdere: 50% di sconto</h3>
                                <p>sul primo premio annuo con la nuova stipula di un’assicurazione in caso di RischioVita</p>
                                <a href="https://www.zurich.ch/it/clientela-privata/previdenza-e-investimento/assicurare/assicurare-i-familiari" target="_blank">
                                    <Button className="mb-4">Scopri di più</Button>
                                </a>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 10, order: 2}} className="text-justify">
                                <img src={zurich1} alt="Yeldo"
                                     className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
